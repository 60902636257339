<template>
  <div :class="multi ? 'mainContentMuti' : 'mainContent'">
    <div class="details" id="details">
      <div class="topNav44" v-show="Show">
        <ul>
          <a @click="Click(0)" :style="{ color: num == 0 ? '#fff' : '#ccc', cursor: 'pointer' }">{{
              $t("C2.v13")
            }}</a>
          <a :style="{ color: num != 0 ? '#fff' : '#ccc',cursor: 'pointer' }" @click="Click(1)">{{
              $t("C2.v14")
            }}</a>
          <a
              style="color: #ccc; margin-left: 0.55rem; cursor: pointer"
              @click="Click(9)"
          >{{ $t("C2.v14a") }}</a
          >
        </ul>
      </div>
      <content-one :content1Data="content1Data" />
      <div class="content2" id="content2">
        <div class="topNav" id="toper" ref="toper">
          <ul>
            <a @click="Click(0)" :style="{ color: num == 0 ? '#fff' : '#ccc', cursor: 'pointer' }">{{
                $t("C2.v13")
              }}</a>
            <a :style="{ color: num != 0 ? '#fff' : '#ccc',cursor: 'pointer' }" @click="Click(3)">{{
                $t("C2.v14")
              }}</a>
            <a
                style="color: #ccc; margin-left: 0.55rem; cursor: pointer"
                @click="Click(9)"
            >{{ $t("C2.v14a") }}</a
            >
          </ul>
        </div>
        <div class="content2-item">
          <!-- <span>精准充气的迅猛龙</span> -->
          <div>
            <video
              class="video"
              ref="audios"
              id="audios"
              poster="https://image.iriding.cc/video/c2.png"
              x5-video-player-type="h5-page"
              webkit-playsinline="true"
              playsinline="true"
              muted
              disablePictureInPicture
              controls
              controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
            >
              <source
                src="https://image.iriding.cc/video/c2.mp4"
                type="video/mp4"
              />
              您的浏览器不支持Video标签。
            </video>
          </div>
          <div
            class="play"
            v-show="isplay"
            v-if="browserVisible"
            @click="isPlay()"
          >
            <img src="@/assets/image/accessories/play1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="content3">
        <div class="topItem height1000">
          <div class="img1 floatL textaR marginR marginT100">
            <span class="font48 fontBold">{{ $t("C2.v16") }}</span>
            <br />
            <span class="font48 fontBold">{{ $t("C2.v17") }}</span>
            <br />
            <span class="font24 marginT25">{{ $t("C2.v18") }}</span>
          </div>
        </div>
        <div class="content-title textaC fontBold colorW font60">
          {{ $t("C2.v19") }}
        </div>
        <template v-for="(item, index) in imgs">
          <div
            class="items height1000 positionR"
            :style="{
              backgroundImage: 'url(' + item.img + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }"
            :key="'img-' + index"
          >
            <div class="img2 marginL color3A" v-if="index === 0">
              <div class="img2-item1">
                <span class="color1A marginT100 block font48 fontBold">{{
                  $t("C2.v20")
                }}</span>
                <span class="font24 fw400 block marginT30">{{
                  $t("C2.v21")
                }}</span>
                <span class="font20 fw400 block bottom30">{{
                  $t("C2.v22")
                }}</span>
              </div>
            </div>
            <div class="img3 floatL textaR marginR" v-if="index === 1">
              <div class="img3-item1 colorW">
                <span class="font48 fontBold marginT100 in-block">{{
                  $t("C2.v23")
                }}</span>
                <br />
                <span style="positionR" class="font24 fw400 marginT30 in-block"
                  >{{ $t("C2.v24") }}
                  <sup
                    style="
                      position: absolute;
                      color: rgb(255, 255, 255) !important;
                      margin-left: -0.1rem;
                      margin-top: -0.05rem;
                    "
                    class="font15"
                    >「2」</sup
                  >
                  &nbsp;&nbsp;&nbsp;&nbsp;{{ $t("C2.v24a") }}
                </span>
                <br />
                <span class="font24 fw400">{{ $t("C2.v24b") }}</span>
              </div>
            </div>
            <div class="img4 floatR marginR" v-if="index === 2">
              <div class="img4-item1 floatR textaR">
                <span class="font48 fontBold color1A in-block marginT100">{{
                  $t("C2.v25")
                }}</span>
                <br />
                <span
                  style="position: relative"
                  class="font24 color3A fw400 in-block marginT30"
                  >{{ $t("C2.v26") }}
                  <sup
                    :style="
                      ios
                        ? 'position: absolute;margin-top: -0.05rem;margin-left: -0.1rem;'
                        : 'position: absolute;margin-top: -0.05rem;margin-left: -0.13rem;'
                    "
                    class="font15"
                    >「3」</sup
                  >&nbsp;&nbsp;{{ $t("C2.v26d") }}
                </span>
              </div>
              <span class="font20 color3A fw400 in-block bottom50 positionA">{{
                $t("C2.v26c")
              }}</span>
            </div>
            <div class="img5" v-if="index === 3">
              <div class="img5-item1">
                <div>
                  <img src="https://image.iriding.cc/c2/img5.png" alt="" />
                  <span>{{ $t("details.v176") }}</span>
                </div>
                <span class="sp1">
                  <span class="sp2">{{ $t("details.v177") }}</span>
                  <span class="three">「3」</span>
                </span>
                <br />
                <p>
                  {{ $t("details.v178") }}
                </p>
              </div>
              <div class="img5-item2">
                <span>{{ $t("details.v191") }}</span>
                <br />
                <span
                  >{{ $t("details.v192") }}
                  <img
                    id="img"
                    src="https://image.iriding.cc/c2/img5.png"
                    alt=""
                  />
                  {{ $t("details.v193") }}</span
                >
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="content5">
        <div class="title">
          <span>{{ $t("C2.v27") }} </span>
          <br />
          <span>{{ $t("C2.v27a") }}</span>
        </div>
        <div class="item1 margin marginB30">
          <div class="item1-inner">
            <span class="item1-title">{{ $t("C2.v28") }}</span>
            <br />
            <span class="item1-title">{{ $t("C2.v29") }}</span>
            <br />
            <span v-html="$t('C2.v30')"></span>
            <div v-if="!showLanguage" class="inner-icon">
              <template v-for="(item, index) in content5.icon">
                <div :key="'icon' + index">
                  <img
                    style="margin-bottom: 0.15rem"
                    :class="'img' + index"
                    :src="item.img"
                    alt=""
                  />
                  <span>{{ $t(item.text1) }} </span>
                  <span>{{ $t(item.text2) }} </span>
                </div>
              </template>
            </div>
            <ul class="list" :style="showLanguage ? 'margin-top:80px' : ''">
              <li v-for="(item, index) in content5.list" :key="'li' + index">
                <span class="marginLeft">
                  <img :class="'li' + index" :src="item.img" alt="" />
                </span>
                <span>{{ $t(item.text) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="item2 margin marginB30">
          <div class="item2-inner">
            <span class="item2-title">{{ $t("C2.v39") }}</span>
            <br />
            <span style="position: relative" class="in-block"
              >{{ $t("C2.v40") }}
              <sup
                style="position: absolute; margin-left: -0.05rem"
                class="font15"
                >「4」</sup
              ></span
            >
            <ul class="inner-ul1 marginT30">
              <li v-for="(item, index) in content5.ul1" :key="'ul1' + index">
                <span
                  class="font27"
                  :class="index === 0 ? 'color4d' : 'colorA88'"
                  >{{ $t(item.text) }}</span
                >
                <div>
                  <img :src="item.img" alt="" />
                </div>
              </li>
            </ul>
            <ul class="inner-ul2">
              <li
                v-for="(item, index) in content5.ul2"
                :key="'ul2' + index"
                class="flexColC fontBold"
                :class="index !== 0 ? 'marginL60' : ''"
              >
                <img :src="item.img" alt="" />
                <span>{{ $t(item.text2) }} </span>
                <span>{{ $t(item.text3) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="margin marginB30"
          :class="showLanguage ? 'item3M' : 'item3'"
        >
          <div
            style="margin-right: 1.54rem"
            class="floatL textaR"
            :class="showLanguage ? 'color0' : 'colorW'"
          >
            <span class="font52 fontBold in-block marginT100">{{
              $t("C2.v50")
            }}</span>
            <br />
            <span class="font30 fw400 in-block marginT30">{{
              $t("C2.v51")
            }}</span>
            <div
              v-if="showLanguage"
              style="
                margin-top: 0.8rem;
                display: flex;
                flex-direction: column;
                align-items: end;
              "
            >
              <img
                style="width: 1.06rem; height: 1.06rem"
                src="@/assets/image/C2PC/f.png"
                alt=""
              />
              <span style="margin-top: 0.2rem; font-size: 0.27rem">{{
                $t("C2.v50")
              }}</span>
            </div>
          </div>
        </div>
        <div class="item4 margin">
          <div style="margin-left: 1.54rem">
            <span class="font52 fontBold marginT100 in-block"
              >{{ $t("C2.v52") }}
            </span>
            <br />
            <span class="font52 fontBold">{{ $t("C2.v52a") }}</span
            ><br />
            <span class="font30 fw400 in-block marginT30">{{
              $t("C2.v53")
            }}</span
            ><br />
            <img src="@/assets/image/C2PC/gearbox.png" alt="" /><br />
            <span class="font27 fw400">{{ $t("C2.v54") }}</span>
          </div>
        </div>
        <div class="topItem2 colorW fontBold textaC">
          {{ $t("C2.v55") }}
          <br />
          {{ $t("C2.v56") }}
        </div>
        <div class="item5 margin marginB30">
          <div style="margin-right: 1.54rem" class="item5-inner textaR">
            <span class="marginT100 in-block font52 fontBold"
              >{{ $t("C2.v57") }} </span
            ><br />
            <span class="font52 fontBold">{{ $t("C2.v57a") }}</span
            ><br />
            <span class="font30 fw400 in-block marginT30">{{
              $t("C2.v58")
            }}</span
            ><br />
            <span class="font30 fw400">{{ $t("C2.v58a") }}</span
            ><br />
            <span class="font24 fw400 lastS"
              >{{ $t("C2.v59") }}{{ $t("C2.v60") }}</span
            >
          </div>
        </div>
        <div class="item6 margin marginB30">
          <div class="item6-1">
            <span class="in-block marginT100 font52 fontBold"
              >{{ $t("C2.v61") }}
              <sup
                style="
                  position: absolute;
                  margin-left: -0.05rem;
                  margin-top: 0.05rem;
                "
                class="font15"
                >「5」</sup
              >
            </span>
            <br />
            <span class="font30 in-block marginT30">{{ $t("C2.v62") }}</span>
          </div>
          <div class="item6-2">
            <table>
              <tr v-for="(item, index) in table" :key="'tr' + index">
                <td v-for="(item2, index2) in item" :key="'td' + index2">
                  <div class="textaC fw400">
                    <img
                      :class="'img' + index + index2"
                      :src="item2.img"
                      alt=""
                    />
                    <span style="color: #010000" class="font24 fw400">{{
                      $t(item2.text)
                    }}</span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="item7 margin marginB30">
          <div class="colorW">
            <span class="font52 fontBold marginT100 in-block">{{
              $t("C2.v75")
            }}</span
            ><br />
            <span class="font30 fw400 in-block marginT30">{{
              $t("C2.v76")
            }}</span
            ><br />
            <span class="font52 fontBold in-block">{{ $t("C2.v77") }}</span
            ><br />
            <span class="font30 fw400 in-block marginT30">{{
              $t("C2.v78")
            }}</span
            ><br />
            <span class="font30 fw400">{{ $t("C2.v78a") }}</span>
          </div>
        </div>
        <div class="item8 margin">
          <div class="colorW">
            <span
              style="position: relative"
              class="font52 fontBold marginT100 in-block"
              >{{ $t("C2.v79") }}
              <sup
                style="
                  position: absolute;
                  color: rgb(255, 255, 255) !important;
                  margin-left: -0.05rem;
                  margin-top: 0.05rem;
                "
                class="font15"
                >「6」</sup
              ></span
            ><br />
            <span class="font30 fw400 in-block marginT30"
              >{{ $t("C2.v80") }}{{ $t("C2.v81") }}{{ $t("C2.v82") }}</span
            ><br />
            <span class="font30 fw400"
              >{{ $t("C2.v83") }}{{ $t("C2.v84") }}</span
            >
          </div>
        </div>
      </div>
      <div class="content6">
        <div class="item1">
          <div class="marginL">
            <span
              style="position: relative"
              class="font48 fontBold marginT100 in-block"
              >{{ $t("C2.v85") }}
              <sup
                style="
                  margin-left: -0.05rem;
                  margin-top: 0.03rem;
                  position: absolute;
                "
                class="font15"
                >「7」</sup
              > </span
            ><br />
            <span class="font48 fontBold">{{ $t("C2.v85a") }}</span
            ><br />
            <span class="font24 fw400 in-block marginT30">{{
              $t("C2.v86")
            }}</span
            ><br />
            <img src="@/assets/image/C2PC/zjpj-icon.png" alt="" /><br />
            <span class="font27 fw400">{{ $t("C2.v87") }}</span>
          </div>
        </div>
        <div class="item2">
          <div class="textaR marginR">
            <span class="marginT100 in-block font48 fontBold"
              >{{ $t("C2.v88") }} </span
            ><br />
            <span class="font48 fontBold">{{ $t("C2.v89") }}</span
            ><br />
            <span class="font24 fw400 in-block marginT30">{{
              $t("C2.v90")
            }}</span
            ><br />
            <span class="font24 fw400">{{ $t("C2.v90a") }}</span
            ><br />
            <span
              style="position: relative"
              class="az font24 fw400 in-block marginT30"
              v-if="!showLanguage"
            >
              <img class="img1" src="@/assets/image/C2PC/sun-icon.png" alt="" />
              {{ $t("C2.v91") }}
              <sup
                class="font15"
                style="
                  margin-left: -0.05rem;
                  margin-top: -0.05rem;
                  position: absolute;
                  color: #b29270;
                "
                >「8」</sup
              ></span
            ><br />
            <img
              class="img2"
              src="@/assets/image/C2PC/app-icon.png"
              alt=""
            /><br />
            <span class="font30 fw400 lj">{{ $t("C2.v92") }}</span>
          </div>
        </div>
        <div class="item3">
          <div>
            <span class="font50 fontBold in-block textaC">{{
              $t("C2.v93")
            }}</span>
            <template v-for="(item, index) in content6">
              <div
                :key="'content6' + index"
                :style="{
                  backgroundImage: 'url(' + item.img + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: ' 100% auto',
                }"
                class="shadown"
              >
                <span
                  style="position: relative"
                  class="font30 fw400 in-block marginT70"
                  >{{ $t(item.text1) }}
                  <sup
                    style="margin-left: -0.05rem; position: absolute"
                    v-if="index === 4"
                    class="font15"
                    >「8」</sup
                  ></span
                ><br />
                <span class="font30 fw400 in-block marginB80"
                  >{{ $t(item.text2) }}
                  <sup
                    style="margin-left: -0.05rem; position: absolute"
                    v-if="index === 5"
                    class="font15"
                    >「9」</sup
                  >
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="content7 backColor">
        <div class="item1 font24 marginL color3A marginR fw400">
          <div
            class="referRetract"
            v-for="(item, index) in obj"
            :key="'span-' + index"
          >
            {{ $t(item) }}
          </div>
        </div>
      </div>
      <div class="de" id="de"></div>
      <list :content8Data="content8" />
      <footer-list :content9Data="content9Data" />
      <to-top />
    </div>
  </div>
</template>

<script>
import ToTop from "../../component/totop/toTop.vue";
import contentOne from "../../component/product/contentOne.vue";
import footerList from "../../component/product/footerList.vue";
import list from "../../component/product/list.vue";
import methods from "@/assets/js/methods";
import util from "@/util.js";

export default {
  name: "pedelecs_c2",
  components: { ToTop, contentOne, footerList, list },
  data() {
    return {
      isplay: true,
      browserVisible: true,
      showLanguage: false,
      content1Data: {
        type: "C2",
        imgLeft: require("@/assets/image/C2PC/bicyle.png"),
        textTitle: "C2.v1",
        plice: "￥4999.00",
        color: "C2.v3",
        option1: "C2.v4",
        info: [
          {
            img1: require("@/assets/image/C2PC/icon-top1.png"),
            text1: "C2.v5",
            img2: require("@/assets/image/C2PC/icon-top2.png"),
            text2: "C2.v9",
          },
          {
            img1: require("@/assets/image/C2PC/icon-top3.png"),
            text1: "C2.v6",
            img2: require("@/assets/image/C2PC/icon-top4.png"),
            text2: "C2.v10",
          },
          {
            img1: require("@/assets/image/C2PC/icon-top5.png"),
            text1: "C2.v7",
            img2: require("@/assets/image/C2PC/icon-top6.png"),
            text2: "C2.v11",
          },
          {
            img1: require("@/assets/image/C2PC/icon-top7.png"),
            text1: "C2.v8",
            img2: require("@/assets/image/C2PC/icon-top8.png"),
            text2: "C2.v12",
          },
        ],
        infoMulti: [
          {
            img1: require("@/assets/image/C2PC/icon-top1.png"),
            text1: "C2.v5",
            img2: require("@/assets/image/C2PC/icon-top7.png"),
            text2: "C2.v8",
            img3: require("@/assets/image/C2PC/icon-top6.png"),
            text3: "C2.v11",
          },
          {
            img1: require("@/assets/image/C2PC/icon-top3.png"),
            text1: "C2.v6",
            img2: require("@/assets/image/C2PC/icon-top2.png"),
            text2: "C2.v9",
            img3: require("@/assets/image/C2PC/icon-top8.png"),
            text3: "C2.v12",
          },
          {
            img1: require("@/assets/image/C2PC/icon-top5.png"),
            text1: "C2.v7",
            img2: require("@/assets/image/C2PC/icon-top4.png"),
            text2: "C2.v10",
          },
        ],
      },
      content8: {
        title: "C2.v120",
        img1: require("@/assets/image/C2PC/1060ff.png"),
        img2: require("@/assets/image/C2PC/725ff.png"),
        tableBottom: [
          { text1: "C2.v121a", text2: "C2.v122" },
          { text1: "C2.v123", text2: "C2.v124" },
          { text1: "C2.v125", text2: "C2.v126" },
          { text1: "C2.v127", text2: "C2.v128" },
          { text1: "C2.v129", text2: "C2.v130" },
          { text1: "C2.v131", text2: "C2.v132" },
          { text1: "C2.v133", text2: "C2.v134" },
          { text1: "C2.v135", text2: "C2.v136" },
          { text1: "C2.v137", text2: "C2.v138" },
          { text1: "C2.v139", text2: "C2.v140" },
          { text1: "C2.v141", text2: "C2.v142" },
          { text1: "C2.v143", text2: "C2.v144" },
          { text1: "C2.v145", text2: "C2.v146" },
          { text1: "C2.v147", text2: "C2.v148" },
          { text1: "C2.v149", text2: "C2.v150" },
          { text1: "C2.v151", text2: "C2.v152" },
        ],
      },
      content9Data: {
        type: "C2",
        footer: [
          {
            img: require("@/assets/image/C2PC/list-icon1.png"),
            text1: "C2.v154",
          },
          {
            img: require("@/assets/image/C2PC/list-icon2.png"),
            text1: "C2.v155c",
          },
          {
            img: require("@/assets/image/C2PC/list-icon3.png"),
            text1: "C2.v156",
          },
          {
            img: require("@/assets/image/C2PC/list-icon4.png"),
            text1: "C2.v157",
          },
          {
            img: require("@/assets/image/C2PC/list-icon5.png"),
            text1: "C2.v158",
          },
          {
            img: require("@/assets/image/C2PC/list-icon6.png"),
            text1: "C2.v159",
          },
          {
            img: require("@/assets/image/C2PC/list-icon7.png"),
            text1: "C2.v160",
          },
          {
            img: require("@/assets/image/C2PC/list-icon8.png"),
            text1: "C2.v161",
          },
          {
            img: require("@/assets/image/C2PC/list-icon9.png"),
            text1: "C2.v162",
          },
        ],
      },
      content5: {
        icon: [
          {
            img: require("@/assets/image/C2PC/black-icon1.png"),
            text1: "C2.v31",
            text2: "C2.v32",
          },
          {
            img: require("@/assets/image/C2PC/black-icon2.png"),
            text1: "C2.v33",
            text2: "C2.v34",
          },
        ],
        list: [
          {
            img: require("@/assets/image/C2PC/black-li-icon1.png"),
            text: "C2.v35",
          },
          {
            img: require("@/assets/image/C2PC/black-li-icon2.png"),
            text: "C2.v36",
          },
          {
            img: require("@/assets/image/C2PC/black-li-icon3.png"),
            text: "C2.v37",
          },
          {
            img: require("@/assets/image/C2PC/black-li-icon4.png"),
            text: "C2.v38",
          },
        ],
        ul1: [
          {
            text: "C2.v41",
            img: require("@/assets/image/C2PC/electric-icon1.png"),
          },
          {
            text: "C2.v42",
            img: require("@/assets/image/C2PC/electric-icon2.png"),
          },
        ],
        ul2: [
          {
            text1: "C2.v41",
            img: require("@/assets/image/C2PC/ele-out-25.png"),
            text2: "C2.v44",
            text3: "C2.v45",
          },
          {
            text1: "C2.v41",
            img: require("@/assets/image/C2PC/ele-out-50.png"),
            text2: "C2.v46",
            text3: "C2.v47",
          },
          {
            text1: "C2.v41",
            img: require("@/assets/image/C2PC/ele-out-75.png"),
            text2: "C2.v48",
            text3: "C2.v49",
          },
        ],
        img5: [
          { img: require("@/assets/image/C2PC/ele-out-25.png") },
          { img: require("@/assets/image/C2PC/ele-out-25.png") },
        ],
      },
      table: [
        [
          {
            img: require("@/assets/image/C2PC/low-q-icon.png"),
            text: "C2.v63",
          },
          {
            img: require("@/assets/image/C2PC/hig-q-icon.png"),
            text: "C2.v64",
          },
          {
            img: require("@/assets/image/C2PC/IPX5-q-icon.png"),
            text: "C2.v65",
          },
          {
            img: require("@/assets/image/C2PC/free-q-icon.png"),
            text: "C2.v66",
          },
        ],
        [
          {
            img: require("@/assets/image/C2PC/overP-q-icon.png"),
            text: "C2.v67",
          },
          {
            img: require("@/assets/image/C2PC/lowP-q-icon.png"),
            text: "C2.v68",
          },
          {
            img: require("@/assets/image/C2PC/a-q-icon.png"),
            text: "C2.v69",
          },
          {
            img: require("@/assets/image/C2PC/d-q-icon.png"),
            text: "C2.v70",
          },
        ],
        [
          {
            img: require("@/assets/image/C2PC/hl-q-icon.png"),
            text: "C2.v71",
          },
          {
            img: require("@/assets/image/C2PC/zd-q-icon.png"),
            text: "C2.v72",
          },
          {
            img: require("@/assets/image/C2PC/130-q-icon.png"),
            text: "C2.v73",
          },
          {
            img: require("@/assets/image/C2PC/hdl-q-icon.png"),
            text: "C2.v74",
          },
        ],
      ],
      content6: [
        {
          img: require("@/assets/image/C2PC/imgS1.png"),
          text1: "C2.v94",
          text2: "C2.v95",
        },
        {
          img: require("@/assets/image/C2PC/imgS2.png"),
          text1: "C2.v96",
          text2: "C2.v97",
        },
        {
          img: require("@/assets/image/C2PC/imgS3.png"),
          text1: "C2.v98",
          text2: "C2.v99",
        },
        {
          img: require("@/assets/image/C2PC/imgS4.png"),
          text1: "C2.v100",
          text2: "C2.v101",
        },
        {
          img: require("@/assets/image/C2PC/imgS5.png"),
          text1: "C2.v102",
          text2: "C2.v103",
        },
        {
          img: require("@/assets/image/C2PC/imgS6.png"),
          text1: "C2.v104",
          text2: "C2.v105",
        },
      ],
      info: [
        {
          img1: require("@/assets/image/C2PC/icon-top1.png"),
          text1: "C2.v5",
          img2: require("@/assets/image/C2PC/icon-top2.png"),
          text2: "C2.v9",
        },
        {
          img1: require("@/assets/image/C2PC/icon-top3.png"),
          text1: "C2.v6",
          img2: require("@/assets/image/C2PC/icon-top4.png"),
          text2: "C2.v10",
        },
        {
          img1: require("@/assets/image/C2PC/icon-top5.png"),
          text1: "C2.v7",
          img2: require("@/assets/image/C2PC/icon-top6.png"),
          text2: "C2.v11",
        },
        {
          img1: require("@/assets/image/C2PC/icon-top7.png"),
          text1: "C2.v8",
          img2: require("@/assets/image/C2PC/icon-top8.png"),
          text2: "C2.v12",
        },
      ],
      imgs: [
        {
          img: "https://image.iriding.cc/c2/img3.png",
          id: 2,
          text1: "details.v38",
          text2: "details.v362",
          text3: "details.v364",
          text4: "details.v39",
          text5: "details.v391",
        },
        {
          img: "https://image.iriding.cc/c2/img4.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: "https://image.iriding.cc/c2/img5.png",
          id: 4,
          text1: "details.v42",
          text2: "details.v362",
          text3: "details.v421",
          text4: "details.v39",
          text5: "details.v43",
        },
      ],
      mes: [
        {
          text1: "details.v53",
          text2: "details.v54",
          text3: "details.v55",

          text4: "details.v56",
          text5: "details.v57",
          text6: "details.v58",
        },
        {
          text1: "details.v59",
          text2: "details.v60",
          text3: "details.v61",

          text4: "details.v62",
          text5: "details.v63",
          text6: "details.v631",
        },
        {
          text1: "details.v64",
          text2: "details.v65",
          text3: "details.v66",
        },
      ],
      c5: [
        {
          text1: "details.v194",
          text2: "details.v195",
        },
        {
          text1: "details.v196",
          text2: "details.v197",
        },
        {
          text1: "details.v198",
          text2: "details.v199a",
          text3: "details.v199b",
        },
        {
          text1: "details.v200",
          text2: "details.v201",
        },
        {
          text1: "details.v202",
          text2: "details.v203",
        },
        {
          text1: "details.v204",
          text2: "details.v205",
        },
        // {
        //   text1: "details.v206",
        //   text2: "±1psi ",
        // },
        {
          text1: "details.v208",
          text2: "details.v209",
        },
        /*{
          text1: "details.v210",
          text2: "5V---3A",
        },*/
      ],

      obj: [
        "C2.v106",
        "C2.v107",
        "C2.v108",
        "C2.v109",
        "C2.v110",
        "C2.v111",
        "C2.v112",
        "C2.v113",
        "C2.v114",
        "C2.v115",
        "C2.v116",
      ],
      activeIndex: false,
      Show: false,
      scrollTop: "",
      toper: "",
      indexNumTop: "",
      num: 0,
      color: false,
      ios: false,
      multi: false,
    };
  },
  mounted() {
    this.$store.state.isShowFooter = true;
    window.addEventListener("scroll", this.getScroll);
    this.$refs.audios.addEventListener("play", this.handlePlay);
    this.$refs.audios.addEventListener("pause", this.handlePause);
    let value = this.$route.path.split("/")[1];
    console.log(value);
    if (value == "zh-hk") {
      this.showLanguage = true;
    }
    if (util.isFirefox() || util.isSafari()) {
      this.browserVisible = false;
    } else {
      this.browserVisible = true;
    }
    if (util.isMac()) {
      this.ios = true;
    }
    let timer = null;
    setTimeout(() => {
      methods.sizeadapt(1920);
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        methods.sizeadapt(1920);
      }, 500);
    };
  },
  methods: {
    handlePlay() {
      this.$refs.audios.play();
      this.isplay = false;
    },
    handlePause() {
      this.$refs.audios.pause();
      this.isplay = true;
    },
    isPlay() {
      let myVideo = document.querySelector("#audios");
      if (myVideo.paused) {
        this.isplay = false;
        myVideo.play();
      } else {
        this.isplay = true;
        myVideo.paused();
      }
    },
    getScroll() {
      this.scrolldata = {
        showMenu: this.Show,
        topid: "#content2",
        target: "#de",
      };
      let data = methods.getScroll(this.scrolldata);
      this.Show = data.showMenu;
      this.$store.state.isShowHeader = data.isShowHeader;
    },
    Click(num) {
      this.num = num;

      if (num == 9) {
        let name = 'EC2.pdf';
        if (this.$store.state.lang != 'zh-cn' && this.$store.state.lang != 'zh-hk') {
          name = 'EC2G.pdf';
        }

        const routeUrl = this.$router.resolve({
          path: "/pdf",
          query: { url: `https://image.iriding.cc/manual/${name}` },
        });

        window.open(routeUrl.href, "_blank");
        return;
      }

      if (num === 0) {
        document.getElementById("content2").scrollIntoView();
        this.num = 0;
      } else if (num === 3) {
        document.querySelector("#de").style.height = "80px";
        this.color = true;
        document.getElementById("de").scrollIntoView();
      } else if (num === 1) {
        document.getElementById("de").scrollIntoView();
        this.color = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
#de {
  padding-top: 50px;
  margin-top: -55px;
}
@import "@/assets/less/screenC2.less";
@import "@/assets/less/common.less";
</style>
